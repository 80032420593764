import * as Yup from "yup";
import {
  ACHIEVEMENTS,
  BILLING,
  EDUCATION,
  PERSONAL_INFO,
  REPRESENTATION,
  WORK_INFO,
} from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import {
  alphanumericWithOutSpacesRegex,
  alphanumericWithSpacesRegex,
  emailValidation,
  fullNameValidation,
  isRequired,
  phoneValidation,
} from "components/modals/helper";
import moment from "moment";
import { isValidPhoneNumber } from "react-phone-number-input";

export const stepDataNewScope = [
  {
    stepName: "Personal",
    stepNumber: "1",
    key: PERSONAL_INFO,
    profile: "PREMIUM",
  },
  {
    stepName: "Education",
    stepNumber: "2",
    key: EDUCATION,
    profile: "PREMIUM",
  },
  {
    stepName: "Professional",
    stepNumber: "3",
    key: WORK_INFO,
    profile: "PREMIUM",
  },
  {
    stepName: "Billing",
    stepNumber: "4",
    key: BILLING,
    profile: "PREMIUM",
  },
  {
    stepName: "Achievements",
    stepNumber: "5",
    key: ACHIEVEMENTS,
    profile: "PREMIUM",
  },
  {
    stepName: "Representations",
    stepNumber: "6",
    key: REPRESENTATION,
    profile: "PREMIUM",
  },
];

export const socialData = [
  { title: "Facebook", key: "facebook" },
  { title: "Instagram", key: "instagram" },
  { title: "Linkedin", key: "linkedin" },
];

export const socialObject = {
  kind: "",
  url: "",
};

export const educationObject = {
  university: "",
  degree: "",
  completedAt: "",
  certificates: [],
};

export const licenseObjectNewScope = {
  state: "",
  licenseNumber: "",
};

export const yearStartedPracticingLawObject = "";

export const workInfoObject = {
  companyName: "",
  jobTitle: "",
  startDate: "",
  endDate: "",
  description: "",
  business: "",
};
export const publicationsObject = {
  name: "",
  url: "",
  publishedAt: "",
};
export const awardsAndAssociationsObject = {
  title: "",
  date: "",
};
export const representationObject = {
  materialIssues: "",
  description: "",
  court: "",
  practiceAreas: [],
};
export const speakingengagementsObject = {
  name: "",
  presentedAt: "",
  entityname: "",
};
export const urlRegex =
  /^((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))[\w-]+(\.[a-zA-Z]+)+((\/)[\w#-]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/g;
export const personalInfoValidationNewScope = Yup.object().shape({
  firstName: fullNameValidation("First Name"),
  lastName: fullNameValidation("Last Name"),
  phone: Yup.string()
    .required(" Phone is required")
    .test({
      name: "phone",
      exclusive: true,
      message: " Phone is invalid",
      test: (value) => {
        return isValidPhoneNumber(value ?? "");
      },
    }),
  email: emailValidation,
  biography: Yup.string().required().label(" Biography"),
  socialURLs: Yup.array().of(
    Yup.object().shape({
      kind: Yup.string(),
      url: Yup.string().when("kind", {
        is: (value) => !!value, // Condition: field1 has a value
        then: Yup.string().required(
          " URL is required when platform is provided"
        ),
        otherwise: Yup.string(), // No validation when field1 is empty
      }),
    })
  ),
  represent: Yup.array().test({
    name: "represent",
    exclusive: true,
    message: "Please select one represent field",
    test: (value) => value?.length > 0,
  }),
  languages: Yup.array()
    .of(
      Yup.object().shape({
        language: Yup.string(),
        oralProficiency: Yup.string().when("language", {
          is: (value) => !!value,
          then: Yup.string().required(" Oral proficiency is required!"),
          otherwise: Yup.string(),
        }),
        writtenProficiency: Yup.string().when("language", {
          is: (value) => !!value,
          then: Yup.string().required(" Written proficiency is required!"),
          otherwise: Yup.string(),
        }),
      })
    )
    .test(
      "validate-first-object",
      " Primary language is required!",
      async function (value) {
        value.forEach(({ language }, ind) => {
          if (ind === 0) {
            if (!language) {
              throw this.createError({
                path: "languages.0.language",
                message: " Primary language is required!",
              });
            }
          }
        });

        return true;
      }
    ),
});

export const educationsValidationNewScope = Yup.object().shape({
  educations: Yup.array().of(
    Yup.object().shape({
      degree: Yup.string().required().trim().label(" Degree"),
      university: Yup.string().required().label(" School"),
      completedAt: Yup.string()
        .required()
        .test("valid-date", " Invalid date", function (value) {
          return moment(value, "MM/DD/YYYY", true).isValid();
        })
        .label(" Completed"),
      // certificates: Yup.array().test({
      //   name: "certificates",
      //   exclusive: true,
      //   message: " Please enter one activities and honor value and hit 'Enter'",
      //   test: (value) => value?.length > 0,
      // }),
    })
  ),
  licenses: Yup.array().of(
    Yup.object().shape({
      licenseNumber: Yup.string(),
      state: Yup.string().required(" State is required"),
    })
  ),
});

export const workInfoValidationSchema = Yup.object().shape({
  workInfo: Yup.array().of(
    Yup.object().shape({
      companyName: Yup.string().min(3).required().label(" Company Name"),
      jobTitle: Yup.string(),
      startDate: Yup.string()
        .required()
        .test("valid-date-yyyy", " Invalid date", function (value) {
          return moment(value, 'YYYY', true).isValid();
        })
        .label(" Start date"),
      endDate: Yup.string()
        .test("valid-date-yyyy", " Invalid date", function (value) {
          if (!value) {
            return true;
          }
          return moment(value, 'YYYY', true).isValid();
        })
        .label(" End date"),
    }),
  ),
  yearStartedPracticingLaw: Yup.string()
      .required()
      .test("valid-date-yyyy", " Invalid date", function (value) {
        return /^\d{4}$/.test(value);
      })
      .label(" Year Started Practicing Law"),
});

export const achievementsValidationNewScope = Yup.object().shape({
  publications: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().when('publishedAt', {
          is: value => value,
          then: Yup.string().required(" Article or Book Title is required"),
          otherwise: Yup.string()
      }),
      publishedAt: Yup.string().when('url', {
            is: value => value,
            then: Yup.string().required(" Date is required"),
            otherwise: Yup.string()
        })
        .test("valid-date", " Invalid date", function (value) {
          if (!value) {
            return true;
          }
          return moment(value, "MM/DD/YYYY", true).isValid();
        })
        .label(" Published"),
    })
  ),
  awards: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().when('date', {
          is: value => value,
          then: Yup.string().required(" Name is required"),
          otherwise: Yup.string()
      }),
      date: Yup.string()
        .test("valid-date", " Invalid date", function (value) {
          if (!value) {
            return true;
          }
          return moment(value, "MM/DD/YYYY", true).isValid();
        })
        .label(" Date"),
    })
  ),
  associations: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().when('date', {
          is: value => value,
          then: Yup.string().required(" Name is required"),
          otherwise: Yup.string()
      }),
      date: Yup.string()
        .test("valid-date", " Invalid date", function (value) {
          if (!value) {
            return true;
          }
          return moment(value, "MM/DD/YYYY", true).isValid();
        })
        .label(" Member Since"),
    })
  ),
  speakingengagements: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().when('presentedAt', {
          is: value => value,
          then: Yup.string().required(" Article or Book Title is required"),
          otherwise: Yup.string()
      }),
      presentedAt: Yup.string().when('entityname', {
          is: value => value,
          then: Yup.string().required(" Date is required"),
          otherwise: Yup.string()
      })
        .test("valid-date", " Invalid date", function (value) {
          if (!value) {
            return true;
          }
          return moment(value, "MM/DD/YYYY", true).isValid();
        })
        .label(" Date"),
      entityname: Yup.string().label(" Entity Name"),
    })
  ),
});

export const representationValidationNewScope = () =>
  Yup.object().shape({
    representations: Yup.array().of(
      Yup.object().shape({
        materialIssues: Yup.string().label(" Brief Title"),
        description: Yup.string().label(" Description"),
        court: Yup.string(),
      })
    ),
  });

/**
 * Formats the date to YYYY
 * @param date 
 * @returns 
 */
export const toYear = (date) =>{
  return date ? moment(date).format('YYYY') : "";
};
