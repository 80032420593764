import logo from "assets/logo_small.png";
import logoWithoutText from "assets/logo_without_text.png";
import Button from "components/button/Button";
import NavDropDown from "components/navbar/NavDropDown";
import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { openSignInModal } from "store/actions/modalActions";
import useClickOutside from "utils/hooks/useClickOutside";
import NavSearch from "./NavSearch";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const authenticated = useSelector((state) => state?.user?.accessToken);
  const firstName = useSelector((state) => state?.user?.firstName);
  const profilePicture = useSelector((state) => state?.user?.profilePicture);
  const dispatch = useDispatch();
  const showMenuRef = useRef(null);
  const location = useLocation();

  useClickOutside([showMenuRef], () => setShowMenu(false));

  const NavbarLogo = useCallback(
    () => (
      <Link to="/" className="flex items-center flex-shrink-0">
        <img
          src={location.pathname === "/" ? logo : logoWithoutText}
          alt="Juricon.ai Logo"
          className="pt-2"
          style={{ width: "auto", height: "54px", maxHeight: "54px" }}
        />
      </Link>
    ),
    [location.pathname]
  );

  return (
    <div className="fixed top-0 left-0 right-0 z-40 bg-white shadow-md">
      <div className="flex items-center max-w-[94%] m-auto py-3">
        {/* Left section with fixed width */}
        <div className="w-[200px] flex-shrink-0">{NavbarLogo()}</div>

        {/* Center section with search */}
        <div className="flex justify-center flex-1">
          {location.pathname !== "/" && (
            <div className="w-full max-w-[500px]">
              <NavSearch />
            </div>
          )}
        </div>

        {/* Right section with fixed width */}
        <div className="w-[200px] flex items-center justify-end flex-shrink-0 gap-4">
          {!authenticated && (
            <Link
              to="/services"
              className="px-4 py-2 font-bold text-white transition duration-300 border-2 rounded-md whitespace-nowrap hover:text-secondary bg-secondary hover:bg-white hover:border-secondary"
            >
              For Lawyers
            </Link>
          )}

          {authenticated ? (
            <div ref={showMenuRef} className="relative flex items-center">
              <div
                className="flex items-center hover:cursor-pointer"
                onClick={() => setShowMenu(!showMenu)}
              >
                {profilePicture ? (
                  <img
                    src={profilePicture}
                    className="rounded-md w-[40px] h-[40px] object-contain"
                    alt={firstName}
                  />
                ) : (
                  <i className="fa-solid fa-user text-borderColor fa-xl"></i>
                )}
                <i className="fa-solid fa-chevron-down text-[#5A5A5A] text-lg pl-1"></i>
              </div>
              {showMenu && (
                <NavDropDown name={firstName} setShowMenu={setShowMenu} />
              )}
            </div>
          ) : (
            <Button
              className="whitespace-nowrap bg-primary py-2 px-4 rounded-md text-white h-[41px] hover:bg-[#0099CB] transition duration-300"
              onClick={() => dispatch(openSignInModal())}
            >
              Sign In
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
