import { PortableText } from "@portabletext/react";
import { CheckCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux"; // Add this import
import { CURRENT_SIGNUP_TAB } from "utils/constants/constants";
import { getServicesPage } from "../sanity/lib/sanityClient";
import { openSignUpModal } from "../store/actions/modalActions";

const ServicesPage = () => {
  const [pageData, setPageData] = useState(null);
  const [annualBilling, setAnnualBilling] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    getServicesPage().then((data) => {
      console.log("Sanity Data:", data);
      setPageData(data);
    });
  }, []);

  if (!pageData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-16 h-16 border-4 rounded-full border-t-blue-500 animate-spin"></div>
      </div>
    );
  }

  const formatPrice = (number) => {
    return number?.toLocaleString("en-US");
  };

  const calculateMonthlyPrice = (plan) => {
    // Check if prices exist
    if (!plan.monthlyPrice || !plan.annualPrice) {
      return {
        price: null,
        savings: 0,
      };
    }

    if (annualBilling) {
      const monthlyPrice = plan.annualPrice / 12;
      const savings =
        ((plan.monthlyPrice - monthlyPrice) / plan.monthlyPrice) * 100;
      return {
        price: monthlyPrice.toFixed(0),
        savings: savings.toFixed(0),
      };
    }
    return {
      price: plan.monthlyPrice,
      savings: 0,
    };
  };

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>{pageData.seoTitle || "For Lawyers"}</title>
        <meta name="description" content={pageData.seoDescription} />
        {pageData.ogImage && (
          <meta property="og:image" content={pageData.ogImage} />
        )}
      </Helmet>

      <div className="max-w-6xl px-6 py-16 mx-auto">
        {/* Title and Description Section */}
        <div className="mb-16 text-center text-secondary ">
          <h1 className="mb-8 text-4xl font-bold text-primary ">
            For Lawyers
          </h1>
          {pageData.description && (
            <div className="max-w-3xl mx-auto prose prose-lg text-secondary ">
              {typeof pageData.description === "string" ? (
                <p className="text-lg  text-secondary font-[600]">
                  {pageData.description}
                </p>
              ) : (
                <PortableText
                  value={pageData.description}
                  components={{
                    block: {
                      normal: ({ children }) => (
                        <p className="mb-4 text-lg text-secondary font-[600]">
                          {children}
                        </p>
                      ),
                    },
                  }}
                />
              )}
            </div>
          )}
        </div>

        {/* Video Section */}
        {pageData.videoUrl && (
          <div className="relative mb-20 overflow-hidden rounded-lg aspect-video">
            <iframe
              className="absolute inset-0 w-full h-full"
              src={pageData.videoUrl}
              title="Service Overview"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        )}

        {/* Billing Toggle */}
        <div className="flex items-center justify-center gap-8 mb-12">
          <span
            className={`text-lg ${
              !annualBilling
                ? "text-blue-500 font-medium"
                : " text-secondary font-medium"
            }`}
          >
            Monthly
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only"
              checked={annualBilling}
              onChange={() => setAnnualBilling(!annualBilling)}
            />
            <div className="relative w-14 h-7">
              <div className="absolute inset-0 bg-blue-500 rounded-full"></div>
              <div
                className={`absolute top-1 w-5 h-5 bg-white rounded-full transition-transform duration-200 ease-in-out ${
                  annualBilling ? "translate-x-8" : "translate-x-1"
                }`}
              ></div>
            </div>
          </label>
          <span
            className={`text-lg ${
              annualBilling
                ? "text-blue-500 font-bold"
                : " text-secondary font-bold"
            }`}
          >
            Annual (SAVE)
          </span>
        </div>

        {/* Pricing Cards */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {pageData.plans.map((plan, index) => {
            const priceInfo = calculateMonthlyPrice(plan);
            const hasPricing = priceInfo.price !== null;

            return (
              <div
                key={plan.name}
                className={`relative border rounded-lg ${
                  index === 1 ? "border-blue-500" : "border-secondary-200"
                }`}
              >
                {index === 1 && (
                  <div className="absolute top-0 right-0 px-4 py-1 text-sm font-medium text-white bg-blue-500">
                    Popular
                  </div>
                )}

                <div className="p-8">
                  <h3 className="mb-4 text-2xl font-bold text-center text-secondary ">
                    {plan.name}
                  </h3>
                  <div className="mb-2 text-center">
                    {hasPricing ? (
                      <>
                        <span className="text-4xl font-bold text-primary">
                          ${formatPrice(Number(priceInfo.price))}
                        </span>
                        <span className="font-medium text-secondary">
                          /month
                        </span>
                      </>
                    ) : (
                      <span className="text-4xl font-bold text-primary">
                        Call for pricing
                      </span>
                    )}
                  </div>
                  {hasPricing && annualBilling && (
                    <div className="mb-6 text-center">
                      <span className="text-sm font-medium text-secondary">
                        billed annually (Save {priceInfo.savings}%)
                      </span>
                    </div>
                  )}

                  <ul className="mt-6 space-y-4">
                    {plan.features.map((feature, idx) => (
                      <li key={idx} className="flex items-start gap-3">
                        <CheckCircle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
                        <span className="font-medium text-secondary">
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>

                  {hasPricing && (
                    <div className="mt-8">
                      {!user && (
                        <button
                          className="bg-primary mt-6 py-3 px-6 rounded-sm text-white w-[183px] h-[56px] hover:bg-[#0099CB] duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                          onClick={() =>
                            dispatch(openSignUpModal(CURRENT_SIGNUP_TAB))
                          }
                        >
                          Get Started
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {/* Outro Section */}
        {pageData.outro && (
          <div className="max-w-3xl mx-auto mt-20 text-center">
            {typeof pageData.outro === "string" ? (
              <p className="text-lg font-[600] text-secondary">
                {pageData.outro}
              </p>
            ) : (
              <PortableText
                value={pageData.outro}
                components={{
                  block: {
                    normal: ({ children }) => (
                      <p className="mb-4 text-lg font-medium leading-relaxed text-secondary">
                        {children}
                      </p>
                    ),
                    h2: ({ children }) => (
                      <h2 className="mb-4 text-2xl font-bold text-secondary ">
                        {children}
                      </h2>
                    ),
                    h3: ({ children }) => (
                      <h3 className="mb-3 text-xl font-bold text-secondary">
                        {children}
                      </h3>
                    ),
                  },
                  list: {
                    bullet: ({ children }) => (
                      <ul className="mb-4 ml-4 text-left list-disc text-secondary">
                        {children}
                      </ul>
                    ),
                    number: ({ children }) => (
                      <ol className="mb-4 ml-4 text-left list-decimal text-secondary">
                        {children}
                      </ol>
                    ),
                  },
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServicesPage;
